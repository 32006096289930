import { Controller } from 'stimulus'
import { GridStack } from 'gridstack'
import 'gridstack/dist/gridstack.min.css'
import 'gridstack/dist/gridstack-extra.css'
import 'gridstack/dist/h5/gridstack-dd-native'

export default class extends Controller {
    GRID_SELECTOR = '.vital-grid'
    static values = {
        url: String,
        userId: String
    }

    STORAGE_KEY = 'vitalParameterColumns'

    static targets = [
        'dropdown'
    ]

    /**
     * @type {GridStack}
     */
    grid

    /**
     * @type {Array<Object>}
     */
    gridState

    /**
     * @type {Number}
     */
    columns

    /**
     * @type {Timeout}
     */
    resizeDebounce

    async connect () {
        this.gridState = await this._getGridState()
        if (!window.document.contains(this.element)) {
            return
        }
        this.columns = this._getColumns()

        this._loadGrid()

        this.grid.on('dragstart', this._onDragStart)
        this.grid.on('dragstop', this._onDragStop)

        window.addEventListener('resize', this._onResize)
        document.addEventListener('turbo:before-render', this.beforeRender)
        document.addEventListener('turbo:before-frame-render', this.beforeFrameRender)

        this.setAutoRefreshTimeout()
    }

    syncElements (currentElement, newElement) {
        const vitalGrid = newElement.querySelector(this.GRID_SELECTOR)
        if (vitalGrid) {
            const oldGrid = currentElement.querySelector(this.GRID_SELECTOR)
            if (oldGrid) {
                vitalGrid.setAttribute('class', oldGrid.getAttribute('class'))
            }
            const newGsElements = newElement.querySelectorAll('[gs-id]')
            newGsElements.forEach((element) => {
                const gsId = element.getAttribute('gs-id')
                if (gsId) {
                    const oldElement = currentElement.querySelector(`[gs-id="${gsId}"]`)
                    if (oldElement) {
                        oldElement.getAttributeNames().forEach((name) => {
                            element.setAttribute(name, oldElement.getAttribute(name))
                        })
                    }
                }
            })
        }
    }

    beforeFrameRender = (e) => {
        const newElement = e.detail.newFrame
        const currentElement = e.currentTarget
        this.syncElements(currentElement, newElement)
    }

    beforeRender = (e) => {
        const originalRender = e.detail.render
        e.detail.render = (currentElement, newElement) => {
            this.syncElements(currentElement, newElement)
            originalRender(currentElement, newElement)
        }
    }

    setAutoRefreshTimeout () {
        this.autorefreshTimeout = setTimeout(() => {
            this.iframes.forEach(frame => {
                frame.reload()
            })
            this.setAutoRefreshTimeout()
        }, 30000)
    }

    disconnect () {
        this.grid.off('dragstart', this._onDragStart)
        this.grid.off('dragstop', this._onDragStop)
        this.grid = null
        clearTimeout(this.autorefreshTimeout)
        this.element.setAttribute('data-cached', 'true')
        window.removeEventListener('resize', this._onResize)
        document.removeEventListener('turbo:before-render', this.beforeRender)
        document.removeEventListener('turbo:before-frame-render', this.beforeFrameRender)
    }

    decreaseWidth (event) {
        const item = event.target.closest('.grid-stack-item')
        const width = +item.getAttribute('gs-w')

        this.grid.update(item, {
            w: width - 1
        })

        this._onChange()
    }

    increaseWidth (event) {
        const item = event.target.closest('.grid-stack-item')
        const width = +item.getAttribute('gs-w')

        const x = +item.getAttribute('gs-x')

        this.grid.update(item, {
            w: width + 1,
            x: x === this.columns - width ? x - 1 : x
        })

        this._onChange()
    }

    decreaseHeight (event) {
        const item = event.target.closest('.grid-stack-item')
        const height = +item.getAttribute('gs-h')

        this.grid.update(item, {
            h: height - 1
        })

        this._onChange()
    }

    increaseHeight (event) {
        const item = event.target.closest('.grid-stack-item')
        const height = +item.getAttribute('gs-h')

        this.grid.update(item, {
            h: height + 1
        })

        this._onChange()
    }

    setColumns (event) {
        this.columns = +event.currentTarget.getAttribute('data-columns')

        const savedColumns = JSON.parse(localStorage.getItem(this.STORAGE_KEY)) ?? {}
        savedColumns[this.userIdValue] = this.columns
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(savedColumns))

        this._loadGrid()
    }

    optimize () {
        this.grid.compact()
        this._onChange()
    }

    reset () {
        this._resetLayout()
        this._onChange()
    }

    _loadGrid () {
        if (this.grid) {
            this.grid.column(this.columns, 'none')
        } else {
            const oldStyleSheetClass = Array.from(this.gridElement.classList).find((cls) => /^grid-stack-instance-/.test(cls))
            this.grid = GridStack.init({
                column: this.columns,
                disableResize: true,
                disableOneColumnMode: true,
                styleInHead: true,
                animate: false,
                draggable: {
                    handle: '.card-header'
                }
            }, this.gridElement)
            if (oldStyleSheetClass) {
                this.gridElement.classList.remove(oldStyleSheetClass)
            }
        }

        const layout = this.gridState?.find(l => l.columns === this.columns)
        if (layout) {
            this._applyLayout(layout)
        } else {
            this._resetLayout()
        }

        this._updateCellHeight()
        this._updateActiveColumns()
        this._updateItemControls()
    }

    _updateCellHeight () {
        this.grid.cellHeight(this.grid.cellWidth() * (this.columns === 1 ? 0.375 : 0.75))
    }

    _saveGrid () {
        const coords = this.grid.save(false)
        const layout = { coords, columns: this.columns }

        this.gridState = this.gridState?.filter(l => l.columns !== this.columns)
        if (this.gridState) {
            this.gridState.push(layout)
        } else {
            this.gridState = [layout]
        }

        // no await so the patch request does not block other actions
        this._patchGridState(layout).then()
    }

    _applyLayout (layout) {
        this.grid.batchUpdate()
        this.items.forEach(item => {
            const coords = layout.coords?.find(coord => coord.id === item.getAttribute('gs-id'))
            if (!coords) {
                return
            }
            this.grid.update(item, { x: coords.x, y: coords.y, w: coords.w, h: coords.h })
        })
        this.grid.commit()
    }

    _resetLayout () {
        this.grid.batchUpdate()
        this.items.forEach(item => {
            const idx = +item.getAttribute('gs-idx')
            this.grid.update(item, {
                x: idx % this.columns,
                y: Math.floor(idx / this.columns),
                w: 1,
                h: 1
            })
        })
        this.grid.commit()
    }

    _updateItemControls () {
        this.items.forEach(item => {
            const width = +item.getAttribute('gs-w')
            const height = +item.getAttribute('gs-h')

            item.querySelector('.gs-decrease-width').classList.toggle('disabled', width === 1)
            item.querySelector('.gs-increase-width').classList.toggle('disabled', width >= this.columns)

            item.querySelector('.gs-decrease-height').classList.toggle('disabled', height === 1)
            item.querySelector('.gs-increase-height').classList.toggle('disabled', height >= 3)
        })
    }

    _updateActiveColumns () {
        const items = this.dropdownTarget.querySelectorAll('.dropdown-item')
        items.forEach(item => {
            const itemColumns = +item.getAttribute('data-columns')
            item.classList.toggle('active', itemColumns === this.columns)
        })
    }

    _onDragStart = () => {
        this._showIFrames(false)
    }

    _onDragStop = () => {
        this._showIFrames(true)
        this._onChange()
    }

    _onResize = () => {
        this._showIFrames(false)
        clearTimeout(this.resizeDebounce)
        this.resizeDebounce = setTimeout(this._onResizeEnd, 100)
    }

    _onResizeEnd = () => {
        if (this._getColumns() !== this.columns) {
            this.columns = this._getColumns()
            this._loadGrid()
        } else {
            this._updateCellHeight()
        }
        this._showIFrames(true)
    }

    _onChange = () => {
        this._updateItemControls()
        this._saveGrid()
    }

    _showIFrames (show) {
        this.iframes.forEach(frame => {
            frame.classList.toggle('d-none', !show)
        })
    }

    async _getGridState () {
        const res = await fetch(this.urlValue, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (res.status !== 200) {
            return []
        }

        return await res.json()
    }

    async _patchGridState (layout) {
        const body = JSON.stringify({
            vital_layout: layout
        })

        await fetch(this.urlValue, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body
        })
    }

    _getColumns () {
        const savedColumns = JSON.parse(localStorage.getItem(this.STORAGE_KEY)) ?? {}
        const savedColumnsForUser = savedColumns[this.userIdValue]
        if (savedColumnsForUser != null) {
            return +savedColumnsForUser
        }

        return Math.min(Math.floor(this.gridElement.clientWidth / 350), 5)
    }

    get items () {
        return this.element.querySelectorAll('.grid-stack .grid-stack-item')
    }

    get iframes () {
        return this.element.querySelectorAll('.grid-stack .grid-stack-item turbo-frame')
    }

    get gridElement () {
        return this.element.querySelector(this.GRID_SELECTOR)
    }
}
